.container-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  align-items: center;
}

.section {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.title-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 780px;
  overflow-x: auto;
  flex: 1;
}

.body-section {
  margin-top: 50px;
  max-width: 780px;
  flex: 1 1;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.section-button {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
}

.section-button>span {
  word-break: break-all;
  text-align: center;
}

.links-container {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
}

.profile {
  width: 200px;
  height: auto;
  border-radius: 50%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border: 5px solid white;
  margin-top: 50px;
}

.smooth {
  transition: .3s cubic-bezier(0.3, 0, 0.4, 1) opacity;
  opacity: 0;
}

.smooth.loaded {
  opacity: 1
}

a {
  text-decoration: none;
}

.top-banner-fixed {
  height: 100px;
  position: fixed;
  top: 0px;
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
}

.fixed-main {
  padding-top: 100px;
}

html, body, #root {
  height: 100%;
}

.name {
  font-size: 28px;
}

.title-name {}

.about-section {
  margin-top: 50px;
  display: flex;
  align-items: flex-start;
  max-width: 780px;
  flex: 1;
  flex-direction: row;
  justify-content: center;
}

@media only screen and (max-width: 600px) {
  .body-section {
    margin: 25px 15px 0px 15px;
  }
  .about-section {
    margin: 25px 15px 0px 15px;
    flex-wrap: wrap;
    justify-content: center;
  }
  .title-section {}
  .links-container {
    justify-content: center;
  }
  .body-section-below-title {
    flex-wrap: wrap;
  }
  .menu-gradient {
    position: absolute;
    right: 0;
    height: 100px;
    width: 25px;
    z-index: 1000;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1))
  }
  .hide-phone {
    display: none;
  }
  .name {
    font-size: 22px;
  }
  .title-name {
    flex-basis: 200px;
    flex-grow: 0;
    flex-shrink: 0;
    margin-left: 25px;
  }
}