
.experience-logos {
  margin-right:25px;
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 20px;
  padding: 15px;
  height:200px;
}

.experience-text {
  flex:1;
}

.experience-title {
  flex:1;
  display: flex;
  justify-content: space-between;
}

.experience-company-logo {

  height: 100%; width: 100%; object-fit: contain
}

.techs-logos {
  margin-top:15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  
}

.tech-logo {
  height:20px;
  width: auto;
  padding: 5px;
  display: flex;
  flex-direction: row;
}

.body-section-below-title {
  margin-top: 45px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.experience-body ul li {
  margin-top: 10px;
}

@media only screen and (max-width: 600px) {

  .experience-company-logo {
    width:100%;
  }

  .body-section-below-title { 
    flex-wrap: wrap;
    justify-content: center;
  }

  .experience-logos {
    width:80%;
    margin: 0px;
    margin-bottom: 25px;
  }

  .techs-logos {
    width: 100%;
  }
}